import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user"
export default class extends Controller {
  connect() {
    $(".new_user").validate({
      rules: {
        "user[username]": {
          required: true,
          minlength: 3,
          noSpecialChars: true
        },
        "user[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "user[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "user[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "user[password]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        }
      },
      messages: {
        "user[username]": {
          required: "Username is required.",
          minlength: 'Username must Contains 3 atleast characters',
          noSpecialChars: 'Username can only contain letters and numbers'
        },
        "user[first_name]": {
          nameregex: 'First name Contains alphabets only',
          required: 'First name is required.',
          minlength: 'First name must be 2-40 characters long.',
          maxlength: 'First name must be 2-40 characters long.'
        },
        "user[last_name]": {
          nameregex: 'Last name Contains alphabets only',
          required: 'Last name is required.',
          minlength: 'Last name must be 2-40 characters long.',
          maxlength: 'Last name must be 2-40 characters long.'
        },
        "user[email]": {
          required: 'Email is required',
          email: 'Email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "user[password]": {
          required: 'Password is required.',
          minlength: 'Password must be 8-15 characters long.',
          maxlength: 'Password must be 8-15 characters long.',
          passwordregex: 'Password must contain at least one letter, one digit, one special character, and be at least 8 characters long'
        }
      },
      errorElement : 'small',
    });

    $(".new_inquiry").validate({
      rules: {
        "inquiry[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "inquiry[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "inquiry[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "inquiry[phone_number]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10,
          required: true
        },
        "inquiry[question]": {
          required: true,
        }
      },
      messages: {
        "inquiry[first_name]": {
          required: "First Name is required.",
          nameregex: 'First Name can only consist of alphabets.',
          minlength: 'First Name must be 2-25 characters long.',
          maxlength: 'First Name must be 2-25 characters long.'
        },
        "inquiry[last_name]": {
          required: "Last Name is required.",
          nameregex: 'Last Name can only consist of alphabets.',
          minlength: 'Last Name must be 2-25 characters long.',
          maxlength: 'Last Name must be 2-25 characters long.'
        },
        "inquiry[email]": {
          required: 'Email is required.',
          email: 'Email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "inquiry[phone_number]": {
          phoneregex: 'Phone number contain only 10 digits.',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
          required: 'Phone number is required.'
        },
        "inquiry[question]": {
          required: 'Comment or Message is required.',
        }
      },
      errorElement : 'small',
    });

    $(".forget_password").validate({
      rules: {
        "user[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "admin_user[email]": {
          required: true,
          email: true,
          emailregex: true
        }
      },
      messages: {
        "user[email]": {
          required: 'Email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "admin_user[email]": {
          required: 'Email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        }
      },errorElement : 'small',
    });

    $(".user_sign_in").validate({
      rules: {
        "user[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "admin_user[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "admin_user[password]": {
          required: true
        },
        "user[password]": {
          required: true
        }
      },
      messages: {
        "user[email]": {
          required: 'Email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "admin_user[email]": {
          required: 'Email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Email must Contains @ and .'
        },
        "admin_user[password]": {
          required: 'Password is required.'
        },
        "user[password]": {
          required: 'Password is required.'
        }
      },errorElement : 'small',
    });

    $('.reset_password').validate({
      rules: {
        "user[password]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        },
        "admin_user[password]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        },
        "user[password_confirmation]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        },
        "admin_user[password_confirmation]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          passwordregex: true
        }
      },
      messages: {
        "user[password]": {
          required: 'The password is required.',
          minlength: 'The password must be 8-15 characters long.',
          maxlength: 'The password must be 8-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        },
        "admin_user[password]": {
          required: 'The password is required.',
          minlength: 'The password must be 8-15 characters long.',
          maxlength: 'The password must be 8-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        },
        "user[password_confirmation]": {
          required: 'The password is required.',
          minlength: 'The password must be 8-15 characters long.',
          maxlength: 'The password must be 8-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        },
        "admin_user[password_confirmation]": {
          required: 'The password is required.',
          minlength: 'The password must be 8-15 characters long.',
          maxlength: 'The password must be 8-15 characters long.',
          passwordregex: 'The password must contain letters and numbers only.'
        }
      }
    })

    $.validator.addMethod("noSpecialChars", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
    }, "Username contains invalid characters.");

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Email Contains alphabets and number only.');

    $.validator.addMethod('passwordregex', function(value, element) {
      return this.optional(element) || /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/.test(value);
    }, 'Password Must follow the password pattern.');

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    });

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /\d{10}\b/g.test(value);
    },'Contains digits only');

    $('input').keyup(function(){
      $('.error-password, .error-confirm').html('')
    })
    $('.reset-password-btn').click(function(){
      var password = $('#admin_user_password').val()
      var confirm_password = $('#admin_user_password_confirmation').val()
      if(confirm_password != password){
        $('.error-confirm').html('Password does not match !!!')
        return false;
      }
    });

    $('input').keyup(function(){
      $('.error-password, .error-confirm').html('')
    })
    $('.reset-password-btn').click(function(){
      var password = $('#user_password').val()
      var confirm_password = $('#user_password_confirmation').val()
      if(confirm_password != password){
        $('.error-confirm').html('Password does not match !!!')
        return false;
      }
    });
  }
}
